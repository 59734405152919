<template>
  <section>
    <div class="container">
      <div class="content" v-html="content"></div>
    </div>
  </section>
</template>

<script>
import { ref, watch } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "LegalPage",
  data() {
    return {
      content: "",
    };
  },
  mounted() {
    this.loadContent();
  },
  methods: {
    loadContent() {
      const page = this.$route.params.id || "terms"; // default to 'terms'
      window.API.getStaticPage(page)
        .then((data) => {
          this.content = data; // assuming the returned object has a 'html' key with the content
        })
        .catch((error) => {
          console.error("Failed to fetch page content:", error);
          this.content = "Error loading page content.";
        });
    },
  },
  watch: {
    $route: "loadContent",
  },
};
</script>

<style scoped>
/* Add any desired styles here */
.container {
  /* Styles for the container */
}
</style>
